// ---
// --- Basic styles
// ---

body {
  background-color: #fff;
  .f-ubuntu;
  color: #333;
  font-size: 16px;
  line-height: 1.25;
}


// Typo
h1, .title_h1,
h2, .title_h2,
h3, .title_h3,
h4, .title_h4,
h5, .title_h5,
h6, .title_h6 {}

h1, .title_h1,
h2, .title_h2,
h3, .title_h3 {}

h4, .title_h4,
h5, .title_h5,
h6, .title_h6 {}

h1, .title_h1 {}
h2, .title_h2 {}
h3, .title_h3 {}
h4, .title_h4 {}
h5, .title_h5 {}
h6, .title_h6 {}

p {}

ul, ol {}
li {}

a:link {
  text-decoration: underline; color: @red;
}
a:visited {
  text-decoration: underline; color: @red;
}
a:hover {
  text-decoration: none; color: @red;
}
a:active {
  text-decoration: none; color: @red;
}





// ---
// --- Layout
// ---
.wrapper {
  width: 1000px;
  margin: 0 auto;
}




// ---
// --- Header
// ---
.header {
  height: 76px;
  line-height: 76px;
  text-align: right;
}
  .header__logo {
    .sprite(199,50,0,0);
    float: left;
    display: inline-block;
    margin-top: 12px;
  }
  .header__nav {
    display: inline-block;
    height: 38px;
    margin-top: 20px;
    font-size: 0;
  }
    .header__social {
      display: inline-block;
      vertical-align: top;
    }
  .header__lang {
    margin-left: 20px;
    display: inline-block;
    border-radius: 18px;
    background-color: @red;
    padding: 4px 3px;
    height: 38px;
    .clearfix;
    span, a {
      display: block;
      float: left;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      .f-ubuntu(500);
      font-size: 14px;
      &:first-child {
        margin-right: 10px;
      }
    }
    span {
      border-radius: 50%;
      background-color: #fff;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }

.social {
  height: 39px;
  line-height: 39px;
  a {
    display: inline-block;
    height: 39px;
    width: 39px;
    border-radius: 50%;
    text-align: center;
    // line-height: 39px;
    padding-top: 13px;
    margin: 0 9px 0 10px;
    vertical-align: top;
    &:first-child {
      margin-left: 0;
    }
    // &:last-child {
    //   margin-right: 0;
    // }
    &:before {
      content: "";
      display: block;
      // vertical-align: top;
      margin: 0 auto;
    }
  }
}
  .social__fb {
    background-color: #4c679e;
    &:before {
      .sprite(9,17,37,32);
    }
  }
  .social__vk {
    background-color: #4f76a1;
    padding-top: 14px !important;
    &:before {
      .sprite(21,12,34,32);
    }
  }
  .social__ig {
    background-color: #4c799e;
    &:before {
      .sprite(20,20,31,32);
    }
  }
  .social__tag, a.social__tag {
    height: 39px;
    vertical-align: top;
    text-decoration: none;
    width: auto;
    text-align: center;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #4c799e;
    border-radius: 19px;
    padding-left: 13px;
    padding-right: 13px;
    font-size: 16px;
    line-height: 39px;
    color: #fff;
  }
  .social__tag--red {
    background-color: @red !important;
  }


// ---
// --- Content
// ---
.body {}

.intro {
  height: 600px;
  width: 100%;
  background-color: #000;
  .bg-r('pattern.png');
  position: relative;
}
  .intro__img {
    position: absolute;
    left: 50%;
    margin-left: -655px;
    top: 67px;
    width: 642px;
    height: 524px;
    .bg('s6.png');
  }
    .intro__tx {
      .sprite(473,425,45,0);
      float: right;
      margin-right: 17px;
      margin-top: 98px;
      position: relative;
      & > div {
        line-height: 1;
        position: absolute;
        color: #fff;
      }
    }
    .intro__tx_1 {
      left: 339px;
      top: 40px;
      font-size: 20px;
      text-transform: lowercase;
    }
    .intro__tx_2 {
      left: 90px;
      width: 300px;
      top: 210px;
      text-transform: uppercase;
      font-size: 21px;
      text-align: center;
    }
    .intro__tx_3 {
      font-weight: 500;
      font-size: 29px;
      width: 100%;
      text-align: center;
      left: 0;
      top: 285px;
    }


.info {
  padding-top: 70px;
  padding-bottom: 85px;
  position: relative;
  .wrapper {
    .clearfix;
  }
}
  .info__l {
    width: 490px;
    float: left;
    p {
      font-size: 22px;
      line-height: 36px;
      mark {
        color: #fff;
        background-color: @red;
        padding-right: 17px;
        padding-left: 17px;
        font-size: 20px;
        display: inline-block;
        height: 34px;
        border-radius: 5px;
        margin-right: 5px;
      }
    }
  }
  .info__r {
    margin-right: 85px;
    width: 320px;
    float: right;
    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
    .info__title {
      display: block;
      height: 80px;
      font-size: 40px;
      line-height: 1;
    }
    .info__date {
      display: block;
      line-height: .9;
      font-size: 70px;
      color: @red;
      margin-bottom: 20px;
    }
  .info__man {
    position: absolute;
    bottom: 0;
    left: 50%;
    bottom: 0;
    margin-left: 463px;
    .sprite(85,144,36,17);
  }


.video {
  height: 495px;
  width: 100%;
  position: relative;
  background-color: #000;
}
  .video__source {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
  }
  .video__gag {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    .bg('video-gag.jpg');
    background-size: cover;
  }
  .video__play {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: -61px;
    margin-left: -61px;
    z-index: 21;
    .sprite(123,123,18,32);
    border: none;
    outline: none;
  }


.sections {
  padding-top: 70px;
  margin-bottom: 20px;
}
  .sections__line {
    text-align: center;
    font-size: 0;
    width: 100%;
    // height: 464px;
    // overflow: hidden;
    .clearfix;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .sections__title {
    text-align: center;
    line-height: 1;
    font-size: 40px;
    margin-bottom: 45px;
  }

.section {
  display: inline-block;
  margin-right: 18px;
  margin-left: 18px;
  // height: 464px;
  padding-bottom: 25px;
  vertical-align: top;
  width: 309px;
  margin-bottom: 30px;
  border: 2px solid #cccccc;
  // padding-top: 44px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
  .section__lbl {
    position: absolute;
    right: -3px;
    .sprite(101,101,31,35);
    top: -3px;
  }
  .section__header {
    // height: 137px;
    height: 120px;
    width: 100%;
    .clearfix;
    text-align: left;
  }
    .section__ic {
      display: inline-block;
      line-height: 100px;
      height: 100px;
      float: left;
      &:before {
        content: "";
        vertical-align: middle;
      }
    }
      .section__ic--it {
        // margin-left: 9px;
        &:before {
          .sprite(59,99,0,39);
        }
        & + .section__title {
          width: 184px;
        }
      }
      .section__ic--mobile {
        margin-left: 9px;
        &:before {
          .sprite(54,99,35,6);
        }
        & + .section__title {
          width: 177px;
        }
      }
      .section__ic--content {
        margin-left: 32px;
        &:before {
          .sprite(83,96,26,6);
        }
        & + .section__title {
          width: 116px;
        }
      }
      .section__ic--movie {
        margin-left: 30px;
        &:before {
          .sprite(80,98,17,6);
        }
        & + .section__title {
          width: 115px;
        }
      }
      .section__ic--photo {
        margin-left: 15px;
        &:before {
          .sprite(77,59,9,6);
        }
        & + .section__title {
          width: 145px;
        }
      }
      .section__ic--design {
        margin-left: 14px;
        &:before {
          .sprite(79,80,0,6);
        }
        & + .section__title {
          width: 142px;
        }
      }
    .section__title {
      display: inline-block;
      height: 83px;
      line-height: 83px;
      font-size: 22px;
      float: right;
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.25;
      }
    }
      a.section__btn {
        display: block;
        .bg('btn.jpg');
        width: 100%;
        height: 82px;
        text-align: center;
        line-height: 82px;
        color: #fff;
        font-size: 20px;
        text-decoration: none;
        margin-bottom: 40px;
        margin-top: 10px;
        &:hover {
          opacity: 0.9;
        }
      }
  .section__tx {
    font-size: 16px;
    text-align: left;
    max-height: 180px;
    overflow: hidden;
    margin-bottom: 30px;
    p {
      line-height: 1.25;
      padding-bottom: 15px;
      margin-bottom: 11px;
      border-bottom: 1px solid #cccccc;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  .section__go, a.section__go {
    height: 48px;
    line-height: 47px;
    background-color: @red;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
    &:hover {
      background-color: (@red + #111);
    }
  }




.photo {
  padding-top: 50px;
}
  .photo__header {
    height: 150px;
    position: relative;
    line-height: 150px;
    font-size: 40px;
    text-align: center;
  }
    .photo__man {
      position: absolute;
      .sprite(85,144,27,17);
      bottom: 0;
      left: 50%;
      margin-left: 501px;
    }
  .photo__gallery {}

.gallery {
  overflow: hidden;
  position: relative;
  height: 352px;
}
  .gallery__line {
    height: 176px;
    overflow: hidden;
    width: 125%;
    .clearfix;
    a {
      display: block;
      width: 15.5%;
      height: 176px;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        max-width: 120%;
        max-height: 99999%;
        top: 0;
        left: 0;
        // transform: translate(-50%, -50%);
        .ie8 & {
          top: 0;
          left: 0;
        }
      }
    }
  }
  .gallery__top {
    position: absolute;
    top: 0;
    left: 0;
    a {
      float: left;
    }
  }
  .gallery__bot {
    position: absolute;
    bottom: 0;
    right: 0;
    a {
      float: right;
    }
  }



.guests {
  min-height: 370px;
  padding-bottom: 50px;
  background-color: #ececec;
  .bg-r('pattern-2.png');
  text-align: center;
  padding-top: 65px;
  position: relative;
}
  .guests__title {
    font-size: 40px;
    line-height: 1;
    display: block;
    margin-bottom: 60px;
  }
  .guests__list {
    line-height: 125px;
    height: 125px;
    text-align: justify;
    margin: 0 auto 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0;
    }
    a {
      display: inline-block;
      height: 125px;
      line-height: 125px;
      // margin: 0 60px;
      text-align: center;
      line-height: 125px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      img {
        vertical-align: middle;
      }
    }
  }
    .guests__list--second {
      width: 90%;
    }
  .guests__man {
    .sprite();
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: 500px;
  }




.orgs {
  padding: 70px 0 80px;
}
  .orgs__line {
    text-align: justify;
    width: 100%;
    font-size: 0;
    &:after {
      display: inline-block;
      width: 100%;
      height: 0;
      content: "";
    }
  }
  .orgs__sTop {
  }
  .orgs__top {
    // height: 181px;
    // padding-top: 76px;
    // margin-bottom: 67px;
  }
  .orgs__bot {
    position: relative;
    padding-top: 53px;
  }
    .orgs__bot__title {
      font-size: 22px;
      color: #999;
      font-weight: 300;
      line-height: 1;
      display: block;
      text-align: center;
      margin: 10px 0 30px;
    }
    .orgs__item {
      position: relative;
      height: 105px;
      display: inline-block;
      line-height: 105px;
      img {
        display: inline-block;
        vertical-align: middle;
      }
    }
      .orgs__item--org {
        display: block;
        width: 100%;
        margin-bottom: 50px;
        text-align: center;
      }
      .orgs__item--gen {
        display: block;
        width: 100%;
        margin-bottom: 50px;
        text-align: center;
      }
      .orgs__item__lk {
        display: block;
        width: 100%;
        height: 100%;
      }
    .orgs__item__title {
      .orgs__bot__title;
    }
  


.action {
  height: 335px;
  .bg('call-to-action-gag.jpg');
  background-size: cover;
  text-align: center;
  position: relative;
}
  .action__tx {
    color: #fff;
    padding-top: 76px;
    mark {
      display: block;
      font-size: 40px;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 18px;
    }
    p {
      display: block;
      font-size: 20px;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 43px;
    }
    a {
      font-size: 20px;
      width: 307px;
      height: 57px;
      line-height: 57px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      background-color: @red;
      display: inline-block;
      border-radius: 5px;
      &:hover {
        background-color: (@red + #111);
      }
    }
  }
  .action__man {
    .sprite(85,144,18,17);
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: 500px;
  }

.action2 {
  height: 335px;
  .bg('call-to-action2-gag.jpg');
  background-size: cover;
  text-align: center;
  position: relative;
}
  .action2__tx {
    color: #fff;
    padding-top: 76px;
    mark {
      display: block;
      font-size: 40px;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 18px;
    }
    p {
      display: block;
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      margin-bottom: 43px;
    }
    a {
      font-size: 20px;
      width: 307px;
      height: 57px;
      line-height: 57px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      background-color: @red;
      display: inline-block;
      border-radius: 5px;
      &:hover {
        background-color: (@red + #111);
      }
    }
  }


.speakers {
  padding-top: 70px;
}
  .speakers__tx {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
    text-align: center;
    font-weight: 300;
  }
    .speakers__title {
      font-size: 40px;
      line-height: 1;
      display: block;
      margin-bottom: 20px;
      font-weight: 400;
    } 
  .speakers__box {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
    .speakers__box__title {
      font-size: 18px;
      line-height: 21px;
      height: 21px;
      position: relative;
      text-align: center;
      margin-bottom: 40px;
      &:before {
        position: absolute;
        content: "";
        display: block;
        top: 10px;
        height: 1px;
        width: 100%;
        background-color: @red;
        z-index: 1;
      }
      span {
        position: relative;
        z-index: 2;
        display: inline-block;
        height: 21px;
        line-height: 21px;
        background-color: #fff;
        padding: 0 20px;
        color: @red;
      }
    }
    .speakers__box__item {
      padding-left: 255px;
      padding-right: 115px;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
      .speakers__box:last-child &:last-child {
        border-bottom: 1px solid #ccc;
      }
    }
      .speakers__box__img {
        float: left;
        margin-left: -170px;
        height: 130px;
        width: 130px;
        border-radius: 50%;
        border: 1px solid #cccccc;
        padding: 4px;
        margin-top: 3px;
        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          display: block;
          background-color: #ccc;
          border: none;
        }
      }
      .speakers__box__cnt {
        font-size: 16px;
        line-height: 24px;
      }
        .speakers__box__name {
          line-height: 1;
          font-size: 24px;
          margin-bottom: 10px;
          display: block;
        }





.groups {
  .clearfix;
  padding-top: 60px;
  padding-bottom: 80px;
}
  .groups__title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 35px;
  }
  .groups__box {
    width: 480px;
    height: 225px;
    float: left;
    &:last-child {
      float: right;
    }
  }



// ---
// --- Footer
// ---
.footer {
  height: 220px;
  background-color: #000;
  .bg-r('pattern.png');
  color: #fff;
  position: relative;
}
  .footer__col {
    width: 33.3333%;
    float: left;
    height: 220px;
  }
  .footer__col-1 {
    padding-top: 48px;
  }
    .footer__logo {
      display: block;
      margin-bottom: 40px;
      &:before {
        .sprite(200,50,20,0);
        content: "";
        display: inline-block;
      }
    }
    .footer__copy {
      font-size: 16px;
      color: #999;
    }
  .footer__col-2 {
    padding-top: 75px;
  }
    .footer__phones {
      mark {
        font-size: 18px;
        margin-bottom: 10px;
        display: block;
        line-height: 1;
      }
      font-size: 26px;
      p {}
    }
  .footer__col-3 {
    padding-top: 75px;
  }
    .footer__social {
      & > a {
        font-size: 18px;
        line-height: 1;
        margin-bottom: 15px;
        text-decoration: none;
        display: block;
        color: #fff;
        transition: none;
        &:hover {
          color: @red;
        }
      }
    }
    .footer__man {
      .sprite(176,218,0,17);
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: 410px;
      padding: 10px 0 0 27px;
      & > span {
        display: inline-block;
        width: 105px;
        height: 45px;
        line-height: 45px;
        font-size: 16px;
        color: #000;
        span {
          line-height: 1.5 !important;
          display: inline-block;
          width: 100%;
          text-align: center;
          vertical-align: middle;
        }
      }
    }




// ---
// --- Magnific Popup
// ---    
@import "parts/magnific";
